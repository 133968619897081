/** @jsx jsx */
import { jsx, Heading, Link as TLink } from 'theme-ui';
import { Link } from 'gatsby';
import Layout from './layout';
import Title from './title';
import Listing from './listing';
import List from './list';
import { Flex } from '@theme-ui/components';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import replaceSlashes from '../utils/replaceSlashes';
// @ts-ignore
import Hero from '../texts/hero';

type PostsProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  }[];
};

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath, tagsPath } = useMinimalBlogConfig();

  return (
    <Layout>
      <section
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: [3, 4, 5],
          mb: [3, 4, 5],
          p: { fontSize: [1, 2, 3], mt: 2 },
        }}
      >
        <Hero />
      </section>
      <Flex
        sx={{
          alignItems: `center`,
          justifyContent: `space-between`,
          flexFlow: `wrap`,
        }}
      >
        <Heading variant='styles.h3'>Articles</Heading>
        <TLink
          as={Link}
          sx={{ variant: `links.secondary` }}
          to={replaceSlashes(`/${basePath}/${tagsPath}`)}
        >
          View all tags
        </TLink>
      </Flex>
      <Listing posts={posts} sx={{ mt: [4, 5] }} />

      {/* <Title text='Latest Posts'>
        <Link to={replaceSlashes(`/${basePath}/${blogPath}`)}>
          Read all posts
        </Link>
      </Title> */}
      {/* <Listing posts={posts} showTags={false} /> */}
      {/* <Listing posts={posts} sx={{ mt: [4, 5] }} /> */}
      {/* <List>
        <Bottom />
      </List> */}
    </Layout>
  );
};

export default Homepage;
