import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* <div style={{ marginLeft: 'auto', marginRight: 'auto', display: 'table' }}>
       <img src='./logo.png' alt='Logo image' />
      </div> */
    }
    {
      /* {/_ <img
      style={{
         marginLeft: 'auto',
         marginRight: 'auto',
         width: '30%',
         borderRadius: '8px',
       }}
      alt='Duck and squirrel'
      src='/duck.jpg'
      /> _/} */
    }
    <blockquote>
      <p parentName="blockquote">{`"Your life is purchased by where you spend your attention." - James Clear`}</p>
    </blockquote>
    {
      /* <iframe
      src='https://benb.substack.com/embed'
      width='100%'
      height='100'
      frameBorder='0'
      scrolling='no'
      > </iframe> */
    }
    {
      /*
      Daily newsletter that sends you 1 developer-centric motivational quote** and nothing else**. */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      